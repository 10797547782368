import { Controller } from "@website/form";
import { useTranslation } from "@website/locale";
import { Button, Radio } from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { ChangeEvent, memo, useCallback, useState } from "react";
import {
  HotelRateSubmitProperties,
  PersianRate,
  RateNumber
} from "./hotel-rate-submit.types";

const Component = ({
  count,
  control,
  englishLabel,
  persianLabel,
  hasDescription
}: HotelRateSubmitProperties) => {
  const router = useRouter();
  const { t } = useTranslation();
  const [value, setValue] = useState(0);

  const onRadioChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement>,
      onChange: (event: unknown) => void
    ) => {
      setValue(Number(event?.target?.value));
      onChange(event?.target?.value);
    },
    []
  );

  return (
    <div className="flex flex-col gap-3">
      <div
        className={
          count > 5
            ? "text-caption text-on-surface-medium-emphasis"
            : "text-subtitle-2"
        }
      >
        {persianLabel}
      </div>
      <fieldset className="flex flex-row flex-wrap items-center gap-3">
        <div
          className={`${
            count > 5 ? "grid grid-cols-5" : "flex"
          } flex-row items-center gap-6 md:flex md:gap-4`}
        >
          {Array.from({ length: count }, (_, index) => (
            <Controller
              control={control}
              name={count > 5 ? englishLabel : `rates.${englishLabel}`}
              render={({ field, fieldState: { error } }) => (
                <Button
                  className="w-[32px]"
                  color={value === index + 1 ? "secondary" : "neutral"}
                  size="x-small"
                  variant={value === index + 1 ? "round" : "round-outline"}
                >
                  <span>
                    {numberConvert(index + 1, { locale: router.locale })}
                  </span>
                  <Radio
                    {...field}
                    className="absolute right-0 top-0 z-10 opacity-0"
                    name="rate"
                    onChange={(event) => onRadioChange(event, field?.onChange)}
                    value={index + 1}
                  />
                </Button>
              )}
            />
          ))}
        </div>
        <div className={`${count > 5 ? "hidden md:block" : "text-caption"}`}>
          <span className="text-on-surface-medium-emphasis px-1">
            {value === 0
              ? t("reviewSubmit.without_rate")
              : t("reviewSubmit.rate_count", {
                  count: numberConvert(value, {
                    locale: router.locale
                  })
                })}
          </span>
          {hasDescription && (
            <span className={value === 0 ? "text-error" : ""}>{`(${
              PersianRate[RateNumber[value]]
            })`}</span>
          )}
        </div>
      </fieldset>
    </div>
  );
};

export const HotelRateSubmit = memo(Component, deepCompare);
