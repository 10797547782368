import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { CarouselV2 } from "@website/ui";
import type { NextPage } from "next";
import styles from "./popular-cities.module.scss";
import { PopularCitiesPropertiesType } from "./popular-cities.types";
import PopularCity from "./popular-city/popular-city";

const PopularCities: NextPage<PopularCitiesPropertiesType> = (properties) => {
  const { list } = properties;
  const { t } = useTranslation();
  return (
    <article className={styles["root"]}>
      <header className={styles["header"]}>
        <div className={styles["header-icon"]}>
          <MaterialIcon name="pin_drop" variant="outlined" />
        </div>
        <h2 className={styles["title"]}>
          {t("homepage.hotel.popular_cities.title")}
        </h2>
      </header>
      {list?.length > 0 && (
        <section className={styles["list"]}>
          <CarouselV2
            itemClass={styles["item"]}
            keyCarousel="PopularCities"
            list={
              Array.isArray(list) &&
              list?.map?.((item) => (
                <PopularCity item={item} key={`${item.state}-${item.city}`} />
              ))
            }
            mode="free"
            options={{
              slides: {
                perView: "auto",
                spacing: 16
              }
            }}
          />
        </section>
      )}
    </article>
  );
};

export { PopularCities };
