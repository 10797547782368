import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { Button, CounterButton, Skeleton } from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { memo } from "react";
const Component = () => {
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <div className="bg-on-color-high-emphasis shadow-1 flex min-w-[218px] flex-col overflow-hidden rounded-xl xl:max-w-[1104px]">
      <div className="flex flex-col">
        <div className="border-outline-thin flex h-[96px] flex-row justify-between gap-2 border-b p-4">
          <div className="flex w-36 flex-col gap-1">
            <Skeleton height={20} shape="rectangle" />
            <Skeleton height={20} shape="rectangle" />
          </div>
          <div className="text-body-2 text-secondary flex cursor-pointer flex-row gap-1">
            <span className="whitespace-nowrap">{t("global.more_detail")}</span>
            <MaterialIcon name="arrow_back_ios" />
          </div>
        </div>
        <div className="flex flex-col items-center justify-between pb-4 pt-3 lg:min-h-[68px] lg:flex-row lg:px-4 lg:pt-4">
          <div className="flex w-full flex-row flex-wrap items-center justify-between">
            <div className="text-caption hidden lg:order-1 lg:me-4 lg:block">
              {t("global.rooms_count")}
            </div>
            <div className="text-caption mr-4 lg:order-3 lg:m-0 ltr:ml-4">
              {t("hotel.room.price_for_night", {
                count: numberConvert(1, {
                  locale: router.locale
                })
              })}
            </div>
            <div className="text-subtitle-1 ml-4 lg:order-4 lg:mr-4 ltr:mr-4 lg:ltr:ml-0">
              <div className="flex flex-row">
                <Skeleton height={20} shape="square" />
                <span className="text-subtitle-2 px-1">
                  {t("global.tooman_currency")}
                </span>
              </div>
            </div>
            <div className="bg-outline-thin mb-4 mt-3 block h-px w-full lg:hidden" />
            <CounterButton
              className="ms-4 !h-[36px] gap-0 lg:order-2 lg:m-0 lg:flex-auto"
              color="secondary"
              decreaseDisabled={false}
              increaseDisabled={false}
              locale="fa"
              size="small"
              value={1}
              variant="outline"
            />
            <Button
              className="ml-4 w-[133px] lg:order-5 lg:col-span-2 lg:m-0 lg:w-[94px] ltr:mr-4"
              size="small"
            >
              {t("hotel.room.reserve_room", {
                count: numberConvert(1, {
                  locale: router.locale
                })
              })}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export const HotelReserveCardSkeleton = memo(Component, deepCompare);
