import { useTranslation } from "@website/locale";
import { CarouselV2, Skeleton } from "@website/ui";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { HotelCard } from "../../hotel-card";
import { CountDownTimer } from "../count-down-timer";
import { HotelLastSecondCityPagePropertiesTypes } from "./hotel-last-second-city-page.type";

const Component: FC<HotelLastSecondCityPagePropertiesTypes> = ({
  lastSecondHotels,
  loading,
  cityTitle
}) => {
  const { t } = useTranslation();

  if (!lastSecondHotels?.length && !loading) return null;

  return (
    <div className="bg-transparent-primary border-transparent-primary mx-[-24px] flex flex-col gap-4 border-y px-6 pb-6 pt-4 xl:mx-0 xl:rounded-xl xl:border">
      <div className="flex flex-col gap-2 md:flex-row md:items-center md:justify-between md:gap-4">
        <div className="flex flex-row items-center gap-2">
          {loading ? (
            <div className="w-[200px]">
              <Skeleton height={24} />
            </div>
          ) : (
            <h2 className="text-subtitle-2 text-primary">
              {t("last_second.main_title_city_page", {
                cityTitle
              })}
            </h2>
          )}
        </div>
        <CountDownTimer
          chipClassName="!h-[32px] pr-1 md:!h-[28px] md:gap-[6px]"
          className="flex gap-1 md:gap-0"
          resetOnEnd
          showTimer={!loading}
          textWrapperClassName="text-body-1 md:text-body-2 text-on-color-high-emphasis gap-3 md:gap-1"
          wrapperClassName="md:w-[143px]"
        />
      </div>

      <div className="h-[112px]">
        {loading ? (
          <Skeleton className="w-[261px]" height={112} />
        ) : (
          <CarouselV2
            itemClass="max-w-[261px]"
            keyCarousel={`suggested_cities_carousel_${cityTitle}`}
            list={
              lastSecondHotels
                ?.slice(0, 3)
                ?.map((hotel) => (
                  <HotelCard hotelInfo={hotel} key={hotel.id} size="xSmall" />
                )) || []
            }
            mode="free"
            options={{
              slides: {
                perView: "auto",
                spacing: 16
              }
            }}
          />
        )}
      </div>
    </div>
  );
};
export const HotelLastSecondCityPage = memo(Component, deepCompare);
