import { useTranslation } from "@website/locale";
import { Loading } from "@website/ui";
import { deepCompare } from "@website/utils";
import { memo } from "react";
import { HotelReserveCardSkeleton } from "../hotel-reserve-rooms-layout/hotel-reserve-card-skeleton";
import { HotelSearchInternational } from "../hotel-search-international";
import { HotelReserveBundleCard } from "./hotel-reserve-bundle-card";
import { HotelReserveBundlesLayoutPropertiesType } from "./hotel-reserve-bundles-layout.types";

const Component = (properties: HotelReserveBundlesLayoutPropertiesType) => {
  const { hotelBundles, searchProperties, loading } = properties;
  const { t } = useTranslation();

  return (
    <div
      className="bg-dim-background mx-[-24px] flex min-h-[304px] flex-col p-6 xl:mx-0 xl:min-h-[264px] xl:max-w-[1200px] xl:rounded-2xl xl:p-12"
      id="hotel_rooms"
    >
      <h2 className="text-subtitle-1 md:text-headline-6 pb-2">
        {t("global.rooms")}
      </h2>
      <div className="text-on-surface-medium-emphasis text-caption pb-4">
        {t("hotel.room.search_text")}
      </div>
      <div>
        <HotelSearchInternational {...searchProperties} searchType="hotel" />
      </div>
      <div className="w-full pt-8 lg:pt-10">
        {loading ? (
          <>
            <div className="flex justify-center">
              <Loading color="primary" size="lg" />
            </div>
            <div className="flex flex-col gap-6 pt-6 lg:pt-[120px] xl:pt-[80px]">
              <HotelReserveCardSkeleton />
              <HotelReserveCardSkeleton />
              <HotelReserveCardSkeleton />
            </div>
          </>
        ) : hotelBundles?.length ? (
          <div className="pt-0 lg:pt-[90px] xl:pt-[60px]">
            <div className="text-subtitle-1 md:text-headline-6 pb-4">
              {t("hotel.room.suitable_rooms")}
            </div>
            <div className="flex flex-col gap-6">
              {hotelBundles
                .filter((room) => room?.availableRooms)
                .map((room) => (
                  <HotelReserveBundleCard key={room.bundleId} {...room} />
                ))}
            </div>
          </div>
        ) : (
          <div className="pt-0 lg:pt-[90px] xl:pt-[60px]">
            <div className="flex flex-col gap-6">
              <span className="block text-center">
                {t("hotel.room.not_found_text")}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const HotelReserveBundlesLayout = memo(Component, deepCompare);
