import { Controller } from "@website/form";
import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { Link, TextField } from "@website/ui";
import { deepCompare, numberConvert } from "@website/utils";
import { useRouter } from "next/router";
import { memo } from "react";
import { Control } from "react-hook-form";
import { HotelRateSubmit } from "../hotel-rate-submit";
import { EnglishDomesticRatings } from "../hotel-rate-submit/hotel-rate-submit.types";
import { HotelReviewPicturesLayout } from "../hotel-review-pictures-layout";
import {
  HotelReviewSubmitContentProperties,
  ReviewSubmitInfoControl
} from "./hotel-review-submit-content.types";

const Component = ({
  title,
  ratesLabel,
  control
}: HotelReviewSubmitContentProperties) => {
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <div className="flex min-w-[218px] max-w-[1200px] flex-col gap-10 pt-10 md:mx-auto md:pb-10">
      <div className="flex flex-col gap-4">
        <div className="text-subtitle-1">
          {t("reviewSubmit.hotel_rate_question", {
            hotel: numberConvert(title, { locale: router.locale })
          })}
          <span className="text-error text-subtitle-2 px-1">
            {`(${t("reviewSubmit.all_items_are_required")})`}
          </span>
        </div>
        <div className="flex flex-col gap-y-8 lg:grid lg:grid-cols-2 lg:gap-x-10">
          {ratesLabel?.map((label) => (
            <HotelRateSubmit
              control={control as unknown as Control<Record<string, number>>}
              count={5}
              englishLabel={label}
              hasDescription
              persianLabel={EnglishDomesticRatings?.[label]}
            />
          ))}
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="text-subtitle-1 flex flex-row items-center gap-1">
          <MaterialIcon
            className="text-success pr-4 ltr:pl-4"
            name="thumb_up"
            variant="outlined"
          />
          <span>
            {t("reviewSubmit.positive_point_question", {
              hotel: numberConvert(title, { locale: router.locale })
            })}
          </span>
        </div>
        <Controller
          control={control}
          name="positivePoints"
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              helperText={t("reviewSubmit.left_characters", {
                count: numberConvert(
                  `${500 - control?._formValues?.positivePoints?.length}`,
                  { locale: router.locale }
                )
              })}
              maxLength={500}
              placeholder={t("comments.positive_point")}
              size="dense"
            />
          )}
        />
      </div>
      <div className="flex flex-col gap-4">
        <div className="text-subtitle-1 flex flex-row items-center gap-1">
          <MaterialIcon
            className="text-error pr-4 ltr:pl-4"
            name="thumb_down"
            variant="outlined"
          />
          <span>
            {t("reviewSubmit.negative_point_question", {
              hotel: numberConvert(title, { locale: router.locale })
            })}
          </span>
        </div>
        <Controller
          control={control}
          name="negativePoints"
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              helperText={t("reviewSubmit.left_characters", {
                count: numberConvert(
                  `${500 - control?._formValues?.negativePoints?.length}`,
                  { locale: router.locale }
                )
              })}
              maxLength={500}
              placeholder={t("comments.negative_point")}
              size="dense"
            />
          )}
        />
      </div>
      <div className="flex flex-col gap-4">
        <div className="text-subtitle-1">
          {t("reviewSubmit.overview_question", {
            hotel: numberConvert(title, { locale: router.locale })
          })}
        </div>
        <Controller
          control={control}
          name="explanations"
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              className="min-h-[120px] w-full py-4"
              fullWidth
              helperText={t("reviewSubmit.left_characters", {
                count: numberConvert(
                  `${1000 - control?._formValues?.explanations?.length}`,
                  { locale: router.locale }
                )
              })}
              maxLength={1000}
              multiLine
              placeholder={t("reviewSubmit.explanations")}
            />
          )}
        />
      </div>
      <div className="flex flex-col gap-4">
        <div className="text-subtitle-1">
          {t("reviewSubmit.pictures_question", {
            hotel: numberConvert(title, { locale: router.locale })
          })}
        </div>
        <HotelReviewPicturesLayout
          control={control as unknown as Control<ReviewSubmitInfoControl>}
        />
      </div>
      <div className="flex flex-col gap-4">
        <div className="text-subtitle-1">
          {t("reviewSubmit.recommendation_question", {
            hotel: numberConvert(title, { locale: router.locale })
          })}
        </div>
        <HotelRateSubmit
          control={control as unknown as Control<Record<string, number>>}
          count={10}
          englishLabel="recommendation"
          hasDescription={false}
          persianLabel={t("reviewSubmit.high_low_possibility", {
            low: numberConvert("1", { locale: router.locale }),
            high: numberConvert("10", { locale: router.locale })
          })}
        />
      </div>
      <div className="text-caption block md:hidden">
        <span>ثبت نهایی، به معنی موافقت با</span>
        <Link
          className="text-caption"
          color="secondary"
          href="https://www.snapptrip.com/policy#:~:text=%D8%B3%DB%8C%D8%A7%D8%B3%D8%AA%20%D8%AD%D9%81%D8%B8%20%D8%AD%D8%B1%DB%8C%D9%85%20%D8%AE%D8%B5%D9%88%D8%B5%DB%8C%20%DA%A9%D8%A7%D8%B1%D8%A8%D8%B1%D8%A7%D9%86&text=%D8%A7%D8%B7%D9%84%D8%A7%D8%B9%D8%A7%D8%AA%20%DA%A9%D8%A7%D8%B1%D8%A8%D8%B1%D8%A7%D9%86%20%D9%86%D8%B2%D8%AF%20%D8%A7%D8%B3%D9%86%D9%BE%E2%80%8C%D8%AA%D8%B1%DB%8C%D9%BE%20%D9%85%D8%AD%D9%81%D9%88%D8%B8,%D8%A7%DB%8C%D9%85%DB%8C%D9%84%20%D8%A8%D9%87%20%DA%A9%D8%A7%D8%B1%D8%A8%D8%B1%D8%A7%D9%86%20%D9%88"
        >
          حریم خصوصی اسنپ‌تریپ
        </Link>
        <span>سیاست حفظ ماست.</span>
      </div>
    </div>
  );
};

export const HotelReviewSubmitContent = memo(Component, deepCompare);
