import { HotelLastSecondPropertiesType } from "@website/hotel-ui";
import { AxiosResponse } from "axios";
import { AxiosLastSecondWithChannel } from "../../../utils";

export const LastSecondUrl = `${process.env.NEXT_PUBLIC_BASE_URL_HAPI}/hotel/promotion/v2/top-rooms`;

const channel = process.env["NEXT_PUBLIC_CHANNEL_TYPE"] || "web";
export const LastSecondService = (): Promise<HotelLastSecondPropertiesType> => {
  AxiosLastSecondWithChannel.interceptors.request.use((config) => {
    if (typeof window !== "undefined" && config && config.headers) {
      config.headers["channel"] = channel === "web" ? "website" : channel;
    }
    return config;
  });
  return AxiosLastSecondWithChannel.get(LastSecondUrl)
    .then(({ data }: AxiosResponse<HotelLastSecondPropertiesType>) => ({
      list: data
    }))
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error("last second hotel", LastSecondUrl, error);
      return null;
    });
};
