import { Hotel } from "@website/icons";
import { useTranslation } from "@website/locale";
import { CarouselV2 } from "@website/ui";
import { TabButton } from "@website/ui-old";
import { FC } from "react";
import { SuggestionItem } from "./components";
import { useSuggestions } from "./suggestion.hook";
import styles from "./suggestions.module.scss";
import { SuggestionsPropertiesType } from "./suggestions.types";

export const Suggestions: FC<SuggestionsPropertiesType> = (properties) => {
  const { selectedTab, list } = useSuggestions(properties);
  const { t } = useTranslation();

  if (list) {
    return (
      <article className={styles["container"]}>
        <header className={styles["header"]}>
          <div className={styles["title-wrapper"]}>
            <div className={styles["icon"]}>
              <Hotel />
            </div>
            <h2 className={styles["title"]}>
              {process.env["NEXT_PUBLIC_CHANNEL_TYPE"] === "snapp-ir"
                ? t("homepage.hotel.suggestions.title_snappir")
                : t("homepage.hotel.suggestions.title")}
            </h2>
          </div>
          <div className={styles["subtitle"]}>
            {t("homepage.hotel.suggestions.subtitle")}
          </div>
        </header>
        <section className={styles["tabs-wrapper"]}>
          <div className={styles["tabs"]} role="tablist">
            {list?.map((item, index) => (
              <TabButton
                color="secondary"
                index={index}
                key={item?.city_title}
                onClick={item?.onClick}
                selected={index === selectedTab}
                size="fill"
                title={item?.city_title}
                variant="round"
              />
            ))}
          </div>
        </section>
        {list[selectedTab]?.hotels?.length > 0 && (
          <section
            aria-labelledby={`tab-button-${selectedTab}`}
            className={styles["list"]}
            id={`tab-${selectedTab}`}
            role="tabpanel"
          >
            <CarouselV2
              itemClass={styles["item"]}
              key={`Suggestions-${list[selectedTab]?.city_title}`}
              keyCarousel={`Suggestions-${list[selectedTab]?.city_title}`}
              list={list[selectedTab]?.hotels?.map((hotel) => (
                <SuggestionItem
                  key={hotel.fa_title}
                  {...hotel}
                  selectedTab={list[selectedTab]?.city_title}
                />
              ))}
              mode="free"
              options={{
                slides: {
                  perView: "auto",
                  spacing: 16
                }
              }}
            />
          </section>
        )}
      </article>
    );
  }
  return null;
};
