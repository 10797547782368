import { useViewPort } from "@website/hooks";
import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { CarouselV2 } from "@website/ui";
import { deepCompare } from "@website/utils";
import { omit } from "lodash";
import Link from "next/link";
import { useRouter } from "next/router";
import { memo } from "react";
import { HotelCard } from "../hotel-card";
import { HotelReserveRecommendationPropertiesTypes } from "./hotel-reserve-recommendation.types";

const Component = ({
  hotelRecommendation,
  onVisibilityChange
}: HotelReserveRecommendationPropertiesTypes) => {
  const { isTablet, isDesktop } = useViewPort();
  const router = useRouter();
  const { t } = useTranslation();
  if (!hotelRecommendation?.length) return null;

  return (
    <div className="flex flex-col gap-4" id="hotel_recomendation">
      <div className="flex flex-col gap-2">
        <div className="flex flex-row items-center justify-between">
          <div>
            <div>
              <MaterialIcon
                className="text-on-surface-medium-emphasis scale-150"
                name="apartment"
              />
              <span className="text-subtitle-1 md:text-headline-6 pr-2 ltr:pl-2">
                {t("recommendation.similar_hotels")}
              </span>
            </div>
          </div>
          <Link
            href={{
              pathname: "/[cityTitle]",
              query: {
                ...omit(router.query, "hotelTitle"),
                stars: hotelRecommendation?.[0]?.hotelDetail?.stars
              }
            }}
          >
            <div className="text-secondary text-subtitle-2 hidden cursor-pointer xl:block">
              {t("recommendation.view_all")}
            </div>
          </Link>
        </div>
      </div>
      {hotelRecommendation?.length > 0 && (
        <CarouselV2
          hasNavigation={false}
          itemClass="pb-2"
          keyCarousel="hotelRecommendation"
          list={hotelRecommendation.map((recommendationHotel, index) => (
            <HotelCard
              className="h-full"
              hotelInfo={recommendationHotel}
              index={index + 1}
              isLazyImage
              key={recommendationHotel.id}
              onVisibilityChange={onVisibilityChange}
              replace
              size="small"
            />
          ))}
          mode="free"
          options={{
            slides: {
              perView: isDesktop ? 4 : isTablet ? 3 : 1.6,
              spacing: 16
            }
          }}
        />
      )}{" "}
    </div>
  );
};
export const HotelReserveRecommendation = memo(Component, deepCompare);
