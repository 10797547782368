import { useIsVisible } from "@website/hooks";
import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { addEventToTracking, USER_TRACKING_EVENTS } from "@website/tracking";
import { MiniChips } from "@website/ui-old";
import { numberConvert } from "@website/utils";
import { FC, RefObject, useCallback, useEffect, useState } from "react";
import styles from "./suggestion-item.module.scss";
import { SuggestionItemPropertiesType } from "./suggestion-item.types";

export const SuggestionItem: FC<SuggestionItemPropertiesType> = (
  properties
) => {
  const {
    cover_media,
    fa_url,
    fa_title,
    rate,
    reviews,
    stars,
    price,
    price_off,
    currency,
    selectedTab,
    accommodation_type_title,
    id
  } = properties;
  const { t, locale } = useTranslation();
  const channel = process.env.NEXT_PUBLIC_CHANNEL_TYPE;
  const finalPrice = price_off < price ? price_off : price;
  const percentOff = ((price - price_off) * 100) / price;
  const { reference, inView } = useIsVisible({});
  const [viewed, setViewed] = useState(false);

  useEffect(() => {
    if (inView && !viewed) {
      setViewed(true);
      typeof window !== "undefined" &&
        window.dataLayer?.push?.({
          event: "view_item_list",
          ecommerce: {
            items: [
              {
                item_name: fa_title,
                item_id: id,
                price: price,
                item_brand: currency,
                item_category: selectedTab,
                item_category2: accommodation_type_title,
                item_category3: `${stars} ستاره`,
                item_list_name: "suggested hotels-home"
              }
            ]
          }
        });
    }
  }, [
    accommodation_type_title,
    currency,
    fa_title,
    id,
    inView,
    price,
    selectedTab,
    stars,
    viewed
  ]);

  const onClickHotel = useCallback(() => {
    addEventToTracking(USER_TRACKING_EVENTS.GENERAL_SEARCH, {
      city: selectedTab,
      product: "dom-hotel",
      search_source: "recommendation-hotels"
    });
    window?.webengage?.track("hotel-search-clicked", {
      ...properties,
      channel,
      source: "hotelRecommendation"
    });
  }, [selectedTab, channel, properties]);

  return (
    <a
      className={styles["container"]}
      href={`${process.env.NEXT_PUBLIC_BASE_URL_B2C}/رزرو-هتل/${fa_url}`}
      onClick={onClickHotel}
    >
      <div
        className={styles["box"]}
        ref={reference as RefObject<HTMLImageElement>}
      >
        <div className={styles["image-container"]}>
          <img
            alt={cover_media.title}
            className={styles["image"]}
            height={168}
            // loading="lazy"
            src={`${process.env.NEXT_PUBLIC_BASE_URL_CDN}/images/288x168${cover_media.media}`}
            width={298} // 288x168 get image with 298x168 :|
          />
        </div>
        <div className={styles["body"]}>
          <div className={styles["details"]}>
            <h3 className={styles["title"]}>{fa_title}</h3>
            <span className={styles["subtitle"]}>
              {t("homepage.hotel.suggestions.count_stars", {
                star: stars
              })}
            </span>
          </div>
          <div className={styles["rates"]}>
            <MiniChips
              EndIcon={<MaterialIcon name="star" />}
              color="success"
              text={numberConvert(rate, { locale })}
              variant="accent"
            />
            <span>({numberConvert(reviews, { locale })})</span>
          </div>
        </div>
        <div className={styles["footer"]}>
          <span className={styles["text"]}>
            {t("homepage.hotel.suggestions.price_per_night")}
          </span>
          <div className={styles["price-wrapper"]}>
            {price_off < price ? (
              <div className={styles["old-price-wrapper"]}>
                <span className={styles["old-price"]}>
                  {numberConvert(Math.trunc(price || 0)?.toLocaleString(), {
                    locale
                  })}
                </span>
                <MiniChips
                  color="primary"
                  text={`٪${numberConvert(Math.trunc(percentOff || 0), {
                    locale
                  })}`}
                  variant="contained"
                />
              </div>
            ) : (
              ""
            )}
            <span className={styles["price"]}>
              {numberConvert(Math.trunc(finalPrice || 0).toLocaleString(), {
                locale
              })}{" "}
              {t("homepage.hotel.suggestions.toman")}
            </span>
          </div>
        </div>
      </div>
    </a>
  );
};
