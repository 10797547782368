import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { Chips, FALLBACK_IMAGE_SM, LazyImage, MiniChips } from "@website/ui";
import { deepCompare, getImage } from "@website/utils";
import { FC, ReactElement, memo } from "react";
import { HotelCardThumbnailPropertiesType } from "./hotel-card-thumbnail.types";

const ThumbnailWrapper: FC<{
  children: ReactElement;
  size: HotelCardThumbnailPropertiesType["size"];
}> = ({ children, size }) =>
  size === "xSmall" ? (
    <div className="size-24 overflow-hidden rounded-[4px]">{children}</div>
  ) : (
    children
  );

const Component: FC<HotelCardThumbnailPropertiesType> = (properties) => {
  const { specialOffer, thumbnail, isLazyImage, primaryBadge, size, title } =
    properties;
  const { t } = useTranslation();
  return (
    <div
      className={`relative flex h-[160px] shrink-0 md:h-auto ${
        size === "small" ? "w-full" : ""
      } ${size === "xSmall" ? "size-24" : ""} ${
        size !== "small" && size !== "xSmall" ? "md:w-[220px] lg:w-[312px]" : ""
      } `}
    >
      {primaryBadge?.text && size !== "xSmall" && size !== "small" && (
        <Chips
          StartIcon={
            primaryBadge.icon ? (
              <MaterialIcon
                className="text-xl"
                name={primaryBadge.icon}
                variant="outlined"
              />
            ) : undefined
          }
          className="absolute inset-x-3 top-3 whitespace-nowrap"
          color={primaryBadge.color}
          text={primaryBadge.text}
        />
      )}
      <ThumbnailWrapper size={size}>
        <LazyImage
          alt={thumbnail?.alt || t("hotel_card.pictures")}
          className={`h-[160] object-cover ${
            size === "small" && "h-[160px] lg:h-[200px]"
          } ${
            size === "xSmall" ? "size-24" : "min-h-full w-full lg:h-[234px]"
          } `}
          fallback={FALLBACK_IMAGE_SM.src}
          loading={isLazyImage ? "lazy" : "eager"}
          src={getImage({
            width: size === "xSmall" ? 200 : 500,
            height: size === "xSmall" ? 200 : 500,
            src: thumbnail?.url
          })}
          title={title}
        />
      </ThumbnailWrapper>
      {specialOffer && (
        <div className="absolute inset-x-3 top-3 whitespace-nowrap">
          <MiniChips
            color="default"
            text={specialOffer}
            variant="round-contained"
          />
        </div>
      )}
    </div>
  );
};

export const HotelCardThumbnail = memo(Component, deepCompare);
