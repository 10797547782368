import { useIsVisible } from "@website/hooks";
import { last, orderBy, toNumber, uniq } from "lodash";
import { HotelPaginationPropertiesType } from "./hotel-pagination.types";

export const useLoadMore = (properties: HotelPaginationPropertiesType) => {
  const { totalCount, pages, setPages } = properties;
  const { reference } = useIsVisible({
    onChange: (inView) => {
      const currentPage = toNumber(last(pages) || 1);
      const hasMorePage = toNumber(totalCount) > currentPage;
      if (inView && hasMorePage) {
        setPages((state) => orderBy(uniq([...state, currentPage + 1])));
      }
    }
  });

  return { reference };
};
