import { MaterialIcon } from "@website/icons";
import { Button, Skeleton, TextField } from "@website/ui";
import { deepCompare, numberConvert, numberSeparator } from "@website/utils";
import { useRouter } from "next/router";
import { ChangeEvent, FC, memo } from "react";
import { useHotelDraftPrepaymentDiscount } from "./hotel-draft-prepayment-discount.hook";
import { HotelDraftPrepaymentDiscountPropertiesType } from "./hotel-draft-prepayment-discount.types";

const Component: FC<HotelDraftPrepaymentDiscountPropertiesType> = (
  properties
) => {
  const router = useRouter();

  const {
    result,
    loading,
    buttonDisabled,
    textInputDisabled,
    isApplyButton,
    removeDiscountCode,
    handleClick,
    setDiscountCode,
    discountCode,
    discountable,
    isSnappPayGateway,
    isFreeCabRequested
  } = useHotelDraftPrepaymentDiscount(properties);

  return (
    <div className="lg:border-outline-thin bg-white px-6 py-4 lg:rounded-lg lg:border lg:p-6">
      {properties?.bookId ? (
        <>
          <div className="lg:text-headline-6 text-subtitle-1 mb-3 py-1 lg:mb-6 lg:p-0">
            کد تخفیف
          </div>
          <div className="text-on-surface-medium-emphasis lg:text-body-2 text-caption mb-4 py-1 lg:mb-6">
            کد تخفیفتان را وارد کنید تا در هزینه سفرتان اعمال کنیم.
          </div>
          <div className="relative w-[312px] max-w-full">
            <TextField
              StartIcon={
                <MaterialIcon
                  className={`${
                    textInputDisabled ? "text-on-surface-disable" : ""
                  } -mt-1`}
                  name="sell"
                  variant="outlined"
                />
              }
              className={`${textInputDisabled ? "pointer-events-none" : ""}`}
              disabled={textInputDisabled}
              fullWidth
              hasFocusStyle
              helperText={
                isSnappPayGateway ? (
                  <span>
                    در پرداخت اقساطی، امکان استفاده از کد تخفیف وجود ندارد.
                  </span>
                ) : isFreeCabRequested ? (
                  <span>
                    به علت انتخاب اسنپ رایگان، امکان استفاده از کد تخفیف وجود
                    ندارد.
                  </span>
                ) : discountable?.discountable ? (
                  result ? (
                    result.discount ? (
                      <span className="text-caption text-success flex items-center gap-1">
                        <MaterialIcon
                          className="text-body-2"
                          name="check_circle"
                          variant="outlined"
                        />
                        {`${numberConvert(numberSeparator(result.discount), {
                          locale: router.locale
                        })}  تومان کد تخفیف اعمال شد.`}
                      </span>
                    ) : (
                      <span className="text-caption text-error flex items-center gap-1">
                        <MaterialIcon
                          className="text-body-2"
                          name="info"
                          variant="outlined"
                        />
                        {result.message}
                      </span>
                    )
                  ) : (
                    ""
                  )
                ) : discountable?.discountDisableReason === "admin" ? (
                  "برای این اقامتگاه، امکان استفاده از کد تخفیف وجود ندارد."
                ) : discountable?.discountDisableReason === "price-engine" ? (
                  "برای این رزرو، امکان استفاده از کد تخفیف وجود ندارد."
                ) : (
                  ""
                )
              }
              label="کد تخفیف"
              onChange={(event_: ChangeEvent<HTMLInputElement>) =>
                setDiscountCode(event_.target.value)
              }
              placeholder="کد تخفیف"
              value={discountCode}
            />
            <Button
              children={isApplyButton ? "اعمال" : "حذف"}
              className="absolute top-[10px] ltr:right-[10px] rtl:left-[10px] rtl:right-auto"
              color={isApplyButton ? "secondary" : "error"}
              disabled={buttonDisabled}
              loading={loading}
              onClick={() => (result ? removeDiscountCode() : handleClick())}
              size="small"
              variant={isApplyButton ? "contained" : "text"}
            />
          </div>
        </>
      ) : (
        <Skeleton height={180} />
      )}
    </div>
  );
};

export const HotelDraftPrepaymentDiscount = memo(Component, deepCompare);
