import { useTranslation } from "@website/locale";
import { Button, Skeleton } from "@website/ui";
import { deepCompare } from "@website/utils";
import { FC, memo } from "react";
import { HotelCardActionPropertiesType } from "./hotel-card-action.types";
import { HotelCardPricing } from "./hotel-card-pricing";
import { HotelCardReservationInfo } from "./hotel-card-reservation-info";

const Component: FC<HotelCardActionPropertiesType> = (properties) => {
  const {
    loading,
    clickLoading,
    pricingInfo,
    reservationInfo,
    size = "responsive"
  } = properties;
  const { t } = useTranslation();

  return (
    <div
      className={
        size === "small"
          ? "flex h-[64px] shrink-0 flex-row items-stretch justify-between px-4 py-2"
          : "flex h-[64px] shrink-0 flex-row flex-wrap items-stretch justify-between px-4 py-2 md:h-full md:min-w-[176px] md:flex-col md:flex-nowrap md:justify-end md:py-4"
      }
    >
      <div
        className={`flex size-full min-h-[55px] grow flex-col justify-center gap-3 ${
          size === "small" ? "md:justify-center" : "md:justify-end"
        } ${clickLoading && "md:hidden"}`}
        hidden={!clickLoading && !loading}
      >
        {(size === "small" ? [1] : [1, 2, 3]).map((key) => (
          <Skeleton
            className="hidden first:block md:block"
            height={25}
            key={key}
            shape="rectangle"
          />
        ))}
      </div>
      {!loading && (
        <>
          <HotelCardReservationInfo {...reservationInfo} size={size} />
          <HotelCardPricing {...pricingInfo} size={size} />
        </>
      )}
      {size !== "small" && (
        <div className="mt-6 hidden md:block">
          <Button divButtonType fullWidth loading={clickLoading}>
            {t("hotel_card.view_and_reserve")}
          </Button>
        </div>
      )}
    </div>
  );
};

export const HotelCardAction = memo(Component, deepCompare);
