import { Control } from "react-hook-form";

export enum PersianRate {
  veryGood = "خیلی خوب",
  good = "خوب",
  ordinary = "معمولی",
  bad = "بد",
  veryBad = "خیلی بد",
  mandatory = "الزامی"
}
export enum RateNumber {
  veryGood = 5,
  good = 4,
  ordinary = 3,
  bad = 2,
  veryBad = 1,
  mandatory = 0
}

export type HotelRateSubmitProperties = {
  control: Control<Record<string, number>>;
  count: number;
  englishLabel: string;
  persianLabel: string;
  hasDescription: boolean;
};

export enum EnglishDomesticRatings {
  cleaning = "وضعیت نظافت اقامتگاه",
  facilities = "امکانات اقامتگاه",
  food = "کیفیت غذا و پذیرایی",
  friendly = "خوشرویی و رفتار کارکنان اقامتگاه",
  hygiene = "رعایت پروتکل‌های بهداشتی",
  location = "موقعیت مکانی و دسترسی اقامتگاه",
  priceQuality = "کیفیت اقامتگاه نسبت به قیمت آن"
}
