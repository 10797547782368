import { MaterialIcon } from "@website/icons";
import { LazyImage } from "@website/ui";
import { deepCompare } from "@website/utils";
import { memo } from "react";
import snapppayDesktop from "./assets/snapppay-desktop.png";
import snapppayMobile from "./assets/snapppay-mobile.png";
import { hotelSnappayPropertiesType } from "./hotel-snapp-pay.types";
const Component = ({
  isEligible,
  condition = "instalmentActive"
}: hotelSnappayPropertiesType) =>
  isEligible ? (
    condition === "instalmentActive" ? (
      <div className="border-ventures-fin-tech w-full rounded-lg border p-4 text-white">
        <div className="flex flex-row items-center gap-2">
          <MaterialIcon
            className="text-ventures-fin-tech"
            name="check_circle"
          />
          <div className="text-body-2 text-ventures-fin-tech">
            پرداخت قسطی با اسنپ‌پی برای شما فعال است.
          </div>
        </div>
      </div>
    ) : (
      <div className="border-ventures-fin-tech w-full overflow-hidden rounded-lg border">
        <div className="hidden md:block">
          <LazyImage src={snapppayDesktop?.src} />
        </div>
        <div className="block md:hidden">
          <LazyImage src={snapppayMobile?.src} />
        </div>
      </div>
    )
  ) : null;

export const HotelSnappPay = memo(Component, deepCompare);
