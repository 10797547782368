import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { LazyImage } from "@website/ui";
import { deepCompare } from "@website/utils";
import { ChangeEvent, memo, useCallback, useRef, useState } from "react";
import { Control } from "react-hook-form";
import { HotelReviewPicture } from "../hotel-review-picture";
import {
  HotelRatePicturesLayoutTypes,
  PictureTypes
} from "./hotel-rate-pictures-layout.types";

const Component = ({ control }: HotelRatePicturesLayoutTypes) => {
  const { t } = useTranslation();
  const [pictures, setPictures] = useState<Array<PictureTypes>>([]);
  const lastIndex = useRef(1);
  const onFieldChangeReference = useRef<(event: unknown) => void>();

  const onChange = useCallback(
    (
      event: ChangeEvent<HTMLInputElement>,
      onFieldChange: (event: unknown) => void
    ) => {
      onFieldChangeReference.current = onFieldChange;
      const reader = new FileReader();
      const pictureFile = event?.target?.files?.[0];
      if (pictureFile) reader.readAsDataURL(pictureFile);
      reader.addEventListener("load", () => {
        onFieldChange([
          ...pictures.map((picture) => Object.values(picture)?.[0]?.file),
          pictureFile
        ]);
        setPictures((previousState) => [
          ...previousState,
          {
            [lastIndex?.current]: {
              base64: String(reader.result),
              file: pictureFile
            }
          }
        ]);
      });
      lastIndex.current += 1;
    },
    [pictures]
  );

  const onDelete = useCallback(
    (item: PictureTypes) => {
      const filteredPictures = pictures.filter(
        (picture) => Object.keys(picture)?.[0] !== Object.keys(item)?.[0]
      );
      setPictures(filteredPictures);
      onFieldChangeReference?.current?.(
        filteredPictures.map((picture) => Object.values(picture)?.[0]?.file)
      );
    },
    [pictures]
  );

  return (
    <div className="flex flex-col">
      <div className="flex flex-row flex-wrap justify-start gap-4">
        {pictures.length < 5 && (
          <HotelReviewPicture
            control={control as unknown as Control<Record<string, Array<File>>>}
            onChange={onChange}
          />
        )}
        {pictures?.map((item) => (
          <div className="relative h-[120px] w-[148px]">
            <LazyImage
              className="h-[120px] w-[148px] rounded-lg"
              src={Object.values(item)?.[0]?.base64}
            />
            <div
              className="bg-on-surface-secondary-medium-emphasis absolute left-0 top-0 m-1 size-8 cursor-pointer rounded-lg px-2 pt-1"
              onClick={() => onDelete(item)}
            >
              <MaterialIcon className="text-white" name="delete" />
            </div>
          </div>
        ))}
      </div>
      <ul className="text-on-surface-medium-emphasis text-caption list-disc px-8 pt-4">
        <li>{t("reviewSubmit.add_picture_description1")}</li>
        <li>{t("reviewSubmit.add_picture_description2")}</li>
      </ul>
    </div>
  );
};

export const HotelReviewPicturesLayout = memo(Component, deepCompare);
