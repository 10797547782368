import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { Button, Link } from "@website/ui";
import { deepCompare } from "@website/utils";
import { memo } from "react";
import { HotelReviewSubmitFooterProperties } from "./hotel-review-submit-footer.types";

const Component = ({
  submitReview,
  dismiss,
  disabled
}: HotelReviewSubmitFooterProperties) => {
  const { t } = useTranslation();

  return (
    <div className="mx-auto flex min-w-[312px] max-w-[1200px] flex-col items-start justify-between gap-4 border-t p-6 md:flex-row md:items-center md:gap-0 md:border-0 md:px-0">
      <div className="text-caption flex flex-col items-start gap-1">
        <div className="text-error">
          <MaterialIcon name="info" variant="outlined" />
          <span>{t("reviewSubmit.fill_all_required")}</span>
        </div>
        <div className="hidden md:block">
          <span>ثبت نهایی، به معنی موافقت با</span>
          <Link
            className="text-caption"
            color="secondary"
            href="https://www.snapptrip.com/policy#:~:text=%D8%B3%DB%8C%D8%A7%D8%B3%D8%AA%20%D8%AD%D9%81%D8%B8%20%D8%AD%D8%B1%DB%8C%D9%85%20%D8%AE%D8%B5%D9%88%D8%B5%DB%8C%20%DA%A9%D8%A7%D8%B1%D8%A8%D8%B1%D8%A7%D9%86&text=%D8%A7%D8%B7%D9%84%D8%A7%D8%B9%D8%A7%D8%AA%20%DA%A9%D8%A7%D8%B1%D8%A8%D8%B1%D8%A7%D9%86%20%D9%86%D8%B2%D8%AF%20%D8%A7%D8%B3%D9%86%D9%BE%E2%80%8C%D8%AA%D8%B1%DB%8C%D9%BE%20%D9%85%D8%AD%D9%81%D9%88%D8%B8,%D8%A7%DB%8C%D9%85%DB%8C%D9%84%20%D8%A8%D9%87%20%DA%A9%D8%A7%D8%B1%D8%A8%D8%B1%D8%A7%D9%86%20%D9%88"
          >
            حریم خصوصی اسنپ‌تریپ
          </Link>
          <span>سیاست حفظ ماست.</span>
        </div>
      </div>
      <div className="flex w-full flex-col gap-4 md:w-auto md:flex-row-reverse">
        <Button color="secondary" disabled={disabled} onClick={submitReview}>
          {t("reviewSubmit.submit_review")}
        </Button>
        <Button color="secondary" href={dismiss} role="link" variant="text">
          {t("reviewSubmit.dismiss")}
        </Button>
      </div>
    </div>
  );
};

export const HotelReviewSubmitFooter = memo(Component, deepCompare);
