import { Skeleton } from "@website/ui";
import { deepCompare } from "@website/utils";
import { memo } from "react";
import { HotelReviewSubmitCard } from "./hotel-review-submit-card";
import { HotelReviewSubmitContent } from "./hotel-review-submit-content";
import { HotelReviewSubmitFooter } from "./hotel-review-submit-footer";
import { HotelReviewSubmitLayoutProperties } from "./hotel-review-submit.layout.types";

const Component = ({
  hotelReviewSubmitCardProperties,
  hotelReviewSubmitContentProperties,
  hotelReviewSubmitFooterProperties,
  isFetching
}: HotelReviewSubmitLayoutProperties) => (
  <div className="border-t-outline-thin border-t bg-white">
    <div className="md:px-6 md:pt-6 lg:px-[83px]">
      {isFetching ? (
        <Skeleton className="rounded-lg" height={250} />
      ) : (
        <HotelReviewSubmitCard {...hotelReviewSubmitCardProperties} />
      )}
    </div>
    <div className="px-6 lg:px-[83px]">
      <HotelReviewSubmitContent {...hotelReviewSubmitContentProperties} />
    </div>
    <div className="md:px-6 lg:px-[83px]">
      <HotelReviewSubmitFooter {...hotelReviewSubmitFooterProperties} />
    </div>
  </div>
);

export const HotelReviewSubmitLayout = memo(Component, deepCompare);
