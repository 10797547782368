import { Controller } from "@website/form";
import { MaterialIcon } from "@website/icons";
import { useTranslation } from "@website/locale";
import { TextField } from "@website/ui";
import { deepCompare } from "@website/utils";
import { ChangeEvent, memo } from "react";
import { HotelReviewPictureTypes } from "./hotel-review-picture.types";

const Component = ({ control, onChange }: HotelReviewPictureTypes) => {
  const { t } = useTranslation();
  return (
    <div className="relative h-[120px] w-[148px]">
      <div className="text-secondary absolute left-[35%] top-[40%] flex flex-row items-center gap-1">
        <MaterialIcon name="add_photo_alternate" variant="outlined" />
        <div className="text-caption">{t("reviewSubmit.add")}</div>
      </div>
      <Controller
        control={control}
        name="pictures"
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            accept="image/png, image/jpeg"
            className="h-[120px] w-[148px] flex-col border-dashed"
            inputClassName="opacity-0 w-full h-full"
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              onChange(event, field?.onChange)
            }
            type="file"
            value=""
          />
        )}
      />
    </div>
  );
};

export const HotelReviewPicture = memo(Component, deepCompare);
